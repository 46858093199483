.star{
 color:red;
}
.table-wrapper{
  overflow-y: scroll;
  height: 500px;
}

.table-wrapper th{
    position: sticky;
    top: 0;
}